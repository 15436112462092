import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import TopSub from "../components/top-sub"
import Container from "../atomic/partials/container"
import { color } from "../components/colors"
import ArticleBox from "../components/article-box"
import Fax from "../images/fax.inline.svg"

const Wrapper = styled.div`
  padding-top: 32px;

  ul {
    list-style: none;
    margin-bottom: 32px;

    .year {
      & > ul {
        margin-top 24px;
      }
    }

    .document {
      display: flex;

      svg {
        height: 16px;
        min-height: 16px;
        max-height: 16px;
        width: 16px;
        min-width: 16px;
        max-width: 16px;
        margin-right: 8px;

        path {
          fill: ${color.primary};
        }
      }

      a {
        text-transform: uppercase;
        text-decoration: none;
        transition: color 0.3s ease;
        color: #000;
        margin-right: 6px;

        &:hover {
          color: ${color.primary};
        }
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
`

const Path = styled.div`
  display: flex;
  margin-bottom: 64px;
  font-weight: 400;

  a {
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.3s ease;
    color: #000;
    margin-right: 6px;

    &:hover {
      color: ${color.primary};
    }
  }
`

const AktyPrawne = ({ data }) => (
  <Layout>
    <Seo />
    <TopSub />
    <Container>
      <Wrapper>
        <Path>
          <Link to="/">SM TARAS PÓŁNOC</Link> / AKTY PRAWNE
        </Path>
        <ul>
          {data.wpPage.aktyPrawne.dokumentyOgolne.map((dokument, i) => (
            <li className="document">
              <Fax />
              <a href={dokument.linkDoDokumentu ? dokument.linkDoDokumentu.mediaItemUrl : "/"} target="_blank">
                {dokument.nazwaDokumentu}
              </a>
            </li>
          ))}
        </ul>
        <ul>
          {data.wpPage.aktyPrawne.dokumentyLata.map((dokument, i) => (
            <li className="year">
              {dokument.rok}
              <ul>
                {dokument.dokument.map((dokument, i) => (
                  <li className="document">
                    <Fax />
                    <a
                      href={dokument.linkDoDokumentu ? dokument.linkDoDokumentu.mediaItemUrl : "/"}
                      target="_blank"
                    >
                      {dokument.nazwaDokumentu}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Wrapper>
    </Container>
  </Layout>
)

export default AktyPrawne

export const pageQuery = graphql`
  query WordpressAktyPrawne {
    wpPage(id: { eq: "cG9zdDoxMDQ=" }) {
      aktyPrawne {
        dokumentyOgolne {
          nazwaDokumentu
          linkDoDokumentu {
            mediaItemUrl
          }
        }
        dokumentyLata {
          rok
          dokument {
            nazwaDokumentu
            linkDoDokumentu {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`
